import React from 'react'

const ProjectArea = () => {
  return (
    <>
      <div className="project-area">
        <div className="container-fluid">
            <div className="row project-row mt-none-30">
                <div className="col-xl-5 col-lg-6 col-md-6 d-flex align-self-stretch mt-30">
                    <div className="project-item">
                        <div className="project-item__thumb project-item__thumb--big">
                            <img src="assets/images/project/p-1.jpeg" alt="" />
                        </div>
                        <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                            <a href="project-details.html" className="project-item__link"><i className="far fa-arrow-right"></i></a>
                            <div className="project-item__content">
                                <h5 className="project-item__subtitle"><span> </span> Book Print</h5>
                                <h4 className="project-item__title">Megazine Cover</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-7 mt-30">
                    <div className="row mt-none-30">
                        <div className="col-xl-6 col-md-6 col-lg-6 mt-30">
                            <div className="project-item">
                                <div className="project-item__thumb">
                                    <img src="assets/images/project/p-2.jpeg" alt="" />
                                </div>
                                <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                    <a href="project-details.html" className="project-item__link"><i className="far fa-arrow-right"></i></a>
                                    <div className="project-item__content">
                                        <h5 className="project-item__subtitle"><span> </span> Book Print</h5>
                                        <h4 className="project-item__title">Megazine Cover</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 col-lg-6 mt-30">
                            <div className="project-item">
                                <div className="project-item__thumb">
                                    <img src="assets/images/project/p-3.jpeg" alt="" />
                                </div>
                                <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                    <a href="project-details.html" className="project-item__link"><i className="far fa-arrow-right"></i></a>
                                    <div className="project-item__content">
                                        <h5 className="project-item__subtitle"><span> </span> Book Print</h5>
                                        <h4 className="project-item__title">Megazine Cover</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 col-lg-6 mt-30">
                            <div className="project-item">
                                <div className="project-item__thumb">
                                    <img src="assets/images/project/p-4.jpeg" alt="" />
                                </div>
                                <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                    <a href="project-details.html" className="project-item__link"><i className="far fa-arrow-right"></i></a>
                                    <div className="project-item__content">
                                        <h5 className="project-item__subtitle"><span> </span> Book Print</h5>
                                        <h4 className="project-item__title">Megazine Cover</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 col-lg-6 mt-30">
                            <div className="project-item">
                                <div className="project-item__thumb">
                                    <img src="assets/images/project/p-5.jpeg" alt="" />
                                </div>
                                <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                    <a href="project-details.html" className="project-item__link"><i className="far fa-arrow-right"></i></a>
                                    <div className="project-item__content">
                                        <h5 className="project-item__subtitle"><span> </span> Book Print</h5>
                                        <h4 className="project-item__title">Megazine Cover</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default ProjectArea
