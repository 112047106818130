import React from 'react';

const About = () => {
  return (
    <>
        <section className="about-area pt-130 pb-130">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-xl-6 pr-0">
                        <div className="about__bg" data-tilt data-tilt-perspective="3000">
                            <img src="assets/images/bg/about-bg-1.png" alt="" />
                        </div>
                    </div>
                    <div className="col-xl-6 pl-80">
                        <div className="section-header mb-40">
                            <h4 className="sub-heading mb-10">About Us</h4>
                            <h2 className="section-title mb-35">Welcome to our printing website</h2>
                            <p>your one-stop destination for all your printing needs. We take pride in offering a wide range of printing services, from digital printing to offset printing, ensuring that we cater to every requirement. Our state-of-the-art equipment and skilled team guarantee high-quality prints that capture the essence of your vision. Whether you need promotional materials, business cards, banners, or customized merchandise, we are here to transform your ideas into tangible, stunning reality. With our commitment to excellence, quick turnaround times, and competitive pricing, we strive to exceed your expectations and become your trusted printing partner. Explore our services today and discover the power of print!</p>
                        </div>
                        {/* <div className="about-lists">
                            <ul>
                                <li><i className="fa fa-check"></i> Magna cubilia sapien vivamus vestibulum iner consectetuer.
                                </li>
                                <li><i className="fa fa-check"></i> Urna faucibus netus Inceptos qu hac sem iaculis lectus.</li>
                            </ul>
                            <a href="about.html" className="site-btn site-btn__s2 mt-55"><span className="icon icon__black"><i className="far fa-arrow-right"></i></span> Read More</a>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default About
