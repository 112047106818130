import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './MobileMenu.css';

const MobileMenu = () => {

    const [showSubMenu, setShowSubMenu] = useState(false);

  return (
    <>
        <nav className="mobile-nav">
            <ul className="metismenu" id="metismenu">
                <li className="menu_has_children">
                    <Link to="/">Home</Link>
                </li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="#" className="has-arrow" aria-expanded="false" onClick={() => setShowSubMenu(!showSubMenu)}>Pages</Link>
                    <ul className={ showSubMenu ? "mm-collapse mm-show" : "mm-collapse" }>
                        <li><Link to="/team">Team</Link></li>
                        <li><Link to="/pricing">Pricing</Link></li>
                        <li><Link to="/faq">Faq</Link></li>
                        <li><Link to="/team-details">Team Details</Link></li>
                        <li><Link to="/service-details">Service Details</Link></li>
                        <li><Link to="/project-details">Project Details</Link></li>
                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                    </ul>
                </li>
                <li><Link to="/service">Services</Link></li>
                <li><Link to="/project">Project</Link></li>
                <li><Link to="/news" className="has-arrow">Article</Link>
                    <ul>
                        <li><Link to="/news-details">Article Details</Link></li>
                    </ul>
                </li>
                <li><Link to="/contact">Contact</Link></li>
            </ul>
        </nav>
        
    </>
  )
}

export default MobileMenu
